import React from 'react';
import SignupButton from '../components/SignupButton'
// import PricingTable from '../components/PricingTable'
import {ReactComponent as KomIcon} from '../../src/assets/gold_KOM.svg'
import {ReactComponent as ZestyRidesIcon} from '../../src/assets/spicy_bike.svg'
import {ReactComponent as CurveArrowIcon} from '../../src/assets/curve-arrow.svg'
// import storage from "../utils/local_storage"
import { useNavigate } from 'react-router-dom';

import into_wind_screenshot from '../assets/images/into_wind.jpeg'
// import cool_run_screenshot from '../assets/images/cool_run1.jpg'
import boring_run_screenshot from '../assets/images/boring_run.jpg'
// import goals_run_screenshot from '../assets/images/goals_run.jpeg'
// import spice_ride_screenshot from '../assets/images/spice_ride.jpeg'
import detailed_activity_screenshot from '../assets/images/detailed_activity_page.png'
import mobile_wind_analysis from '../assets/images/mobile_wind_analysis.png'
import lemmon_wind_analysis from '../assets/images/lemmon_wind_analysis.png'
import dashboard_overview from '../assets/images/dashboard_overview.png'
import ss1 from '../assets/images/ss1.jpg'
import ss2 from '../assets/images/ss2.jpg'
// import ss3 from '../assets/images/ss3.jpg'
import ss4 from '../assets/images/ss4.jpg'
import ss5 from '../assets/images/ss5.jpg'
import ss6 from '../assets/images/ss6.jpg'
import ss7 from '../assets/images/ss7.jpg'
import map_3D from '../assets/images/map_3D.png'
import peak_map from '../assets/images/peak_map.png'
import activity_graph from '../assets/images/activity_graph.png'
import walkthrough1 from '../assets/videos/walkthrough_trim_1.mov'
// import walkthrough2 from '../assets/videos/walkthrough_trim_2.mov'
import map_page_walkthrough_1 from '../assets/videos/map_page_walkthrough_1.mov'
// import map_page_walkthrough_2 from '../assets/videos/map_page_walkthrough_2.mov'
import song_list from '../assets/images/song_list.png'

function HomePage() {
  const token = localStorage.getItem('Token');
  const navigate = useNavigate();

  const handleDashboardButtonClick = () => {
    navigate('/dashboard'); // Replace '/path-to-navigate' with your route
  };

  const DashboardButton = () => {
    return (
                <button className="btn btn-success text-lg" onClick={handleDashboardButtonClick}>Go to Dashboard</button>

    )
  }

  return (
    <div className="flex flex-col items-center justify-center space-y-8 m-4">
      {/* Row 1 */}
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center mt-4 w-full">
        <div className=" text-center m-6 lg:w-1/2">
          <div className="flex flex-col items-center justify-center mx-auto pb-4">
            <ZestyRidesIcon width="95" height="95" className=" mr-4"/>
            <p className="text-6xl font-bold">ZestyRides</p>
          </div>
          <p className="text-2xl font-bold">Spice up your Strava activities</p>
          <p className="text-m pb-4">with highly customizable information added automatically to your titles and descriptions.</p>
        {token ? <DashboardButton/>:<SignupButton/>}
        </div>
        <div className="relative py-8 my-4 lg:w-1/2 flex justify-center items-center">
          <div className="flex items-center space-x-4 px-8">
            <img 
              src={boring_run_screenshot}
              alt="Boring Run"
              className="w-1/3 lg:w-2/5 transform -rotate-6 translate-x-5 -translate-y-2 rounded-lg z-0" 
            />
            {/* <div className="flex-grow relative flex justify-center ">
              <div className="absolute z-20 w-[25vw] lg:w-[12vw] top-1/4 -translate-x-1/2 -translate-y-[145%] lg:top-1/4 lg:-translate-x-1/5 lg:-translate-y-[160%]">
                <CurveArrowIcon className="w-full h-auto" />
              </div>
            </div> */}
            <img 
              src={ss5} 
              alt="Zested Run"
              className="w-3/5 lg:w-3/5 transform rotate-6 -translate-x-5 -translate-y-2 rounded-lg z-1" 
            />
          </div>
        </div>

      </div>
      <div className="divider"></div>
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center lg:p-8">
        <div className="flex-1 text-center p-4">
          <p className="text-2xl">Open the ZestyRides activity analysis extension right in your Strava descriptions.</p>
          <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature">
            <KomIcon width="35" height="35" className="m-2" />
          </div>
          <div className="flex flex-col items-center justify-center">
            {/* <p className="text-md italic">No additional app or credentials required. </p> */}
            <p className="text-md font-bold">Opens inside the Strava app, from a unique link in your activity description.</p>
            {/* <p className="text-lg">Ever notice the lack of an interactive map and elevation profile in the Strava mobile app?</p> */}
            <ul className="text-left list-none list-inside mx-auto text-md pt-4">
              <li>Scroll across the elevation profile to learn how long it actually took to climb to that peak.</li>
              <li>View your best efforts and Spotify songs on the map and elevation graph.</li>
              {/* <li>View played Spotify song locations on the map and elevation profile.</li> */}
              <li>Analyze the wind's impact on your activity.</li>
              <li>Get your unique link right in your description for your followers to view.</li>
              {/* <li>Address the lack of this feature in the Strava and Apple Fitness mobile apps.</li> */}
            </ul>
          
          </div>
        </div>
        {/* <div className="flex justify-center max-w-lg pt-4 lg:order-first">
            <img 
              src={detailed_activity_screenshot} 
              alt="Description2"
              className="rounded-lg w-2/3 lg:w-1/2" 
            />
          </div> */}
         <div className="flex flex-row gap-4 overflow-auto py-4 bg-neutral rounded-lg px-4 w-full sm:w-3/4 lg:w-1/2">
           <video 
             src={walkthrough1} 
             autoPlay
             loop
             muted
             className="rounded-lg h-auto max-h-96 object-cover my-auto" 
             poster={detailed_activity_screenshot}
           />
           {/* <video 
             src={walkthrough2} 
             autoPlay
             loop
             muted
             className="rounded-lg h-auto max-h-96 object-cover my-auto" 
            //  poster={detailed_activity_screenshot}
           />  */}
            {/* <img 
              src={detailed_activity_screenshot} 
              alt="Description2"
              className="rounded-lg h-auto max-h-96 object-cover my-auto" 
            /> */}
            <img 
              src={mobile_wind_analysis} 
              alt="Description2"
              className="rounded-lg h-auto max-h-96 object-cover my-auto" 
            />
          <img 
            src={dashboard_overview} 
            alt="Description2"
            className="rounded-lg h-auto max-h-96 object-cover my-auto" 
          />
          <img src={song_list} className=" max-h-96 object-fit my-auto rounded-lg" alt="Description 2" />

        </div>
      </div>
      <div className="divider"></div>
      {/* Row 2 */}
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center lg:p-8">
        <div className="flex-1 text-center py-4">
          <p className="text-2xl">Select from multiple zest types to spice up those mundane daily activity posts.</p>
          <div className="flex flex-col sm:flex-row justify-evenly ">
            <div className="p-4 mx-auto">
              <p className="text-lg justify-center pt-4 font-bold">Add some spice to the titles</p>
              <div className="inline-block">
                <ul className="text-left list-none list-inside">
                  <li>😄 Puns</li>
                  <li>👨 Dad jokes</li>
                  <li>💪 Motivational quotes</li>
                  <li>📖 Daily Bible verse</li>
                </ul>
              </div>
              <p className="text-lg pt-4 font-bold">And some data to descriptions</p>
              <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature">
                <KomIcon width="35" height="35" className="" />
              </div>
              <div className="inline-block">
              <ul className="text-left list-none list-inside">
                <li>⛰️ Summited mountain peaks</li>
                <li>⏱️ Climb/descent time and distance</li>
                <li>🤸‍♂️ Move Percentage</li>
                <li>🏃 Run/walk time and distance</li>
                <li>🎿 Days on the mountain count</li>
                <li>🚡 Ski/Snowboard lift & slope times</li>
                <li>🥾 Percentage of activity on new trails*</li>
                <li>☀️ Weather conditions</li>
                <li>💨 Percentage of ride into headwind</li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" className="h-4 inline-block align-text-bottom mr-2" fill="#1ed760">
                    <path d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8Z"/>
                    <path fill="#000000" d="M406.6 231.1c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3zm-31 76.2c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm-26.9 65.6c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4z"/>
                  </svg>
                  Spotify songs played during activity
                </li>
                <li>🚲 Gear usage milestones</li>
                {/* <li>🏂 Lift & slope count*</li> */}
                <li>🏆 Goal progress tracking</li>
                <li>🐆 Animal top speed equivalent</li>
                <li>🔥 Burned calorie food equivalent</li>
                <li>🫀 Activity total heart beat count</li>
                <li>⏱️ (Half)marathon time equivalent</li>
              </ul>
              * coming soon
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex overflow-auto py-4 space-x-4 px-4 bg-neutral rounded-lg" style={{ maxHeight: '24rem', maxWidth: '32rem' }}> */}
        <div className="flex flex-row gap-4 overflow-auto py-4 bg-neutral rounded-lg px-4 w-full sm:w-3/4 lg:w-1/2">
            <img src={ss1} className="h-auto max-h-96 my-auto object-cover rounded-lg" alt="Description 1" />
            <img src={ss2} className="h-auto max-h-96 object-cover my-auto rounded-lg" alt="Description 2" />
            {/* <img src={ss3} className="h-auto max-h-96 object-cover rounded-lg" alt="Description 3" /> */}
            <img src={ss4} className="h-auto max-h-96 object-cover my-auto rounded-lg" alt="Description 4" />
            <img src={ss5} className="h-auto max-h-96 object-cover my-auto rounded-lg" alt="Description 5" />
            <img src={ss6} className="h-auto max-h-96 object-cover my-auto rounded-lg" alt="Description 6" />
        </div>
      </div>
      <div className="divider"></div>
      {/* Row 2 */}
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center lg:p-8">
        <div className="flex-1 text-center py-4">
          <p className="text-2xl">All your activities and peaks: mapped 🌎 and graphed 📈📉</p>
          <div className="flex flex-col sm:flex-row justify-evenly ">
            <div className="mx-auto">
              <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature">
                <KomIcon width="35" height="35" className="m-2" />
              </div>
          <div className="flex flex-col p-4 lg:w-2/3 mx-auto">
            <ul className="text-left list-none list-inside mx-auto">
              <li className="text-md"> 🌍 Explore a 3D map of your activity history and crossed peaks.</li>
              {/* <li className="text-md">Can't find that old Strava activity based on its name? Find it on your map of your entire Strava history.</li> */}
              <li className="text-md">⛰️ View peak summit totals and open contributing activities in Strava with one click.</li>
              {/* <li className="text-md">Analyze your peak and activity trends across your entire history on Strava.</li> */}
              {/* <li>Monthly/Annual weather condition summary (coming soon)</li> */}
            </ul>
          </div>

            </div>
          </div>
        </div>
        {/* <div className="flex overflow-auto py-4 space-x-4 px-4 bg-neutral rounded-lg" style={{ maxHeight: '24rem', maxWidth: '32rem' }}> */}
        <div className="flex flex-row gap-4 overflow-auto py-4 bg-neutral rounded-lg px-4 w-full sm:w-3/4 lg:w-1/2">
            <video 
             src={map_page_walkthrough_1} 
             autoPlay
             loop
             muted
             className="rounded-lg  max-h-96 object-fit my-auto"
             poster={peak_map}
           />
           {/* <video 
             src={map_page_walkthrough_2} 
             autoPlay
             loop
             muted
             className="rounded-lg  max-h-96 object-fit my-auto"
            //  poster={peak_map}
           /> */}
            <img src={map_3D} className=" max-h-96 object-fit my-auto rounded-lg" alt="Description 1" />
            <img src={activity_graph} className=" max-h-96 object-fit my-auto rounded-lg" alt="Description 2" />
        </div>
      </div>
      <div className="divider"></div>
      {/* Row 3 */}
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center mb-4">
        <div className="flex-1 justify-center text-center p-4">
          <p className="text-2xl">Get more out of your weather data.</p>
          <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature">
            <KomIcon width="35" height="35" className="m-2" />
          </div>
          <div className="flex flex-col ">
            <ul className="text-left list-disc list-inside mx-auto">
              <li>Pulls more weather data than a Strava subscription into the description</li>
              <li>Sunrise/Sunset times when relevant</li>
              <li>Percentage of rides into a headwind</li>
              <li>UV index</li>
              <li>Air Quality index</li>
              <li>View a wind impact analysis in your dedicated detailed activity page</li>
              {/* <li>Monthly/Annual weather condition summary (coming soon)</li> */}
            </ul>
          </div>
        </div>
        {/* <div className="flex justify-center max-w-lg lg:order-first pt-4">
            <img 
              src={into_wind_screenshot} 
              alt="Description2"
              className="rounded-lg w-3/4 lg:w-2/3" 
              />
        </div> */}
        <div className="flex flex-row gap-4 overflow-auto py-4 bg-neutral rounded-lg px-4 w-full sm:w-3/4 lg:w-1/2">
            <img src={into_wind_screenshot} className="h-auto max-h-96 object-cover rounded-lg" alt="Description 1" />
            <img src={lemmon_wind_analysis} className="h-auto max-h-96 object-cover rounded-lg" alt="Description 2" />
        </div>
      </div>
      {/* New row */}
      <div className="divider"></div>
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 items-center">
        <div className="flex-1 text-center p-4">
          <p className="text-2xl">Track goals to get achievement and progress feedback conveniently in your activities.</p>
          <div className="flex justify-center items-center text-center tooltip" data-tip="ZestyRides Pro Feature">
            <KomIcon width="35" height="35" className="m-2" />
          </div>
          <p className="text-lg">Are you trying to ride 2025 miles in 2025 or run every day for a month?</p>
          <p className="text-lg">Get immediate feedback on your goals right in your descriptions to stay motivated to the end!</p>
          <div className="flex flex-col lg:flex-row justify-evenly pt-4">
              <ul className="text-left list-none list-inside mx-auto">
                <li>🏃 Daily Activity Goal
                  {/* <p>Daily Activity Goal </p> */}
                  <br/>
                  <i className="text-xs ml-5">e.g. Daily Run 67 of 2025</i>
                </li>
                <li>💯 Cummulative Distance, Duration, & Elevation Goals
                  {/* <p></p> */}
                  <br/>
                  <i className="text-xs ml-5">e.g. Annual run distance 15/1000 miles, 5 mi behind goal</i><br/>
                  <i className="text-xs ml-5">e.g. February run distance: 90/75 mi, on track for 122 mi</i>
                </li>
                <li>🏔️ Everest Notifications 
                  <br/>
                  <i className="text-xs ml-5">e.g. 3rd cycling everest achieved in 2025</i>
                </li>                
                {/* <li>👑 KOM/QOM/CR Tally (coming soon)
                  <br/>
                  <i className="text-xs ml-5">e.g. 37th KOM earned</i>
                </li> */}
              </ul>
          </div>
        </div>
        <div className="flex justify-center max-w-lg pt-4">
            <img 
              src={ss7} 
              alt="Description2"
              className="rounded-lg w-3/4 lg:w-2/3" 
            />
          </div>
      </div>
      
      <div className="flex flex-col justify-center items-center">
        <KomIcon width="35" height="35" className="" />
        {/* <p>Get a 3 weeks of ZestyRides Pro for free</p>
        <p>(no payment details required)</p> */}
        <p className="text-center text-2xl font-bold pb-4">Sign in with Strava to spice things up.</p>
        {token ? <DashboardButton/>:<SignupButton/>}
        {/* <p className="text-center text-sm italic">Optional 14-day free trial for goals and weather.</p> */}
      </div>
        {/* <iframe allowtransparency frameborder='0' height='160' scrolling='no' src='https://www.strava.com/clubs/1212641/latest-rides/fccf4b3c870b40964a43eb33fee1afe99414869f?show_rides=false' width='300'></iframe> */}
    </div>
    )
  ;
}

export default HomePage;